


/* .App {
  text-align: center;

} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.navbar-light .navbar-brand {
  color: white;
}

.navbar-light .navbar-nav .nav-link {
   color:white; 
   padding-right: 70%;
   padding-left: 20%;
   
}


.navbar-light .navbar-nav .nav-link:hover{
  color:red;
}

.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
  color: red;
}

.App-header {
  
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
#App
{
  margin-top: -50px;
  
}
.navbar
{
  text-align: center;
  /* background-color:red; */
  color: white;
  font-family:'Courier New', Courier, monospace;
  font-size: 23px;
  /* padding: 10px; */
  position: fixed;
  width: -webkit-fill-available;
  top: 0;
  z-index: 1;
  transition: top 0.3s;
  min-height: 70px;
  
}




/* .bg-dark{
  background:transparent !important;
  transition: 0.5s ease;
} */
/* .bg-dark.scrolled{
  /* background: linear-gradient(90deg, rgb(66, 2, 194)0%, rgb(224, 57, 15)100%) !important; 
  background: red;
  
} */


.navbar.active{
  
  
  transition: 100ms ease;
}
/* .navbar.scrolled {
  background-color: white!important;
  transition:5ms ease;
} */

.navbar-nav>li>a:hover{
  color:#1a1a1a;
} 
#navbar{
  transition:1000ms ease;
  background:transparent;
}
#navbar{
  background:#000;
}

.mr-auto{
  color:white !important; 
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }


}

#mainContainerLayout{
  width:3000px;
}


.container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 2060px;
}



@media (min-width: 1365px){
.navbar-expand-lg .navbar-collapse {
    display: flex!important;
    flex-basis: auto;
    border-left: outset;
}
}

/* aquamarine; */

