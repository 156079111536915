body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #4f4b4b;
    /* background-color:rgb(42, 42, 42); */
    background-color:rgba(187, 184, 184, 0);
     /* background-image: url("./Images/IMG_20191105_233722__01.jpg");  */




    background-image: url("./Images/IMG_20210212_130826__01.jpg");
    
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
  }

  #mainheader
  {
    background-color: black;
    text-align: center;
    font-size: 20px;
    color:white;
    font-family: 'Courier New', Courier, monospace;

  }
  #unipic{
    padding-left: -300px;
  }
  #greetingcardMain{
    margin-top: 100px;
    background-color:#fff0 ;
  }

  #greetingCard{
    /* background-color: rgb(71, 71, 71); */
    color:white;
    font-size: 25px;
    font-family: 'Fira Sans', sans-serif;
    margin-top: 50px;
    text-align: center;
    border-radius: .80rem;

  }
  #greetingCard {
    background: linear-gradient(300deg, #255b4d, #782420, #4c8343, #0c0e78);
    background-size: 800% 100%;

    -webkit-animation: greetingCard 30s ease infinite;
    -moz-animation: greetingCard 30s ease infinite;
    animation: greetingCard 30s ease infinite;
}
/* @keyframes greetingCard {
  0% {background-position: 0%}
  100% {background-position: 100%}
} */
@-webkit-keyframes greetingCard {
    0%{background-position:0% 88%}
    50%{background-position:100% 13%}
    100%{background-position:0% 88%}
}
@-moz-keyframes greetingCard {
    0%{background-position:0% 88%}
    50%{background-position:100% 13%}
    100%{background-position:0% 88%}
}
@keyframes greetingCard {
    0%{background-position:0% 88%}
    50%{background-position:100% 13%}
    100%{background-position:0% 88%}
}





  #greetingTitle{
    font-size: 35px !important;
    font-weight: bold;
    font-family: 'Fira Sans', sans-serif;
  }
  /* #element.style {
    transform: translate3d(-10%, 8.53993%, 0px);
} */

#parralax{
    transform: translate3d(-10%, 8.53993%, 0px);
}
#rPic{
  width:500px;
  padding: 20px;
}

#uPic{
  width: 500px;
  padding: 20px;
}
#pictureContainer{
  
  margin-top: 20px;
  padding: 30px;
  text-align: center;
}

#mainContainerLayout {
  width: 80%;
}
